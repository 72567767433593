export const Style = {
  heading: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  }),
  para: (isArabic) => ({
    height: "37px",
    flexShrink: "0",
    color: `var(--Gray, #B4B6B6)`,
    textalign: "center",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: { xs: "14px", sm: "16px" },
    fontstyle: "normal",
    fontweight: "400",
    lineheight: "normal",
    margin: "10px 0 14px",
  }),
  allTickets: {
    width: "100%",
    padding: "10px 0",
    boxSizing: "border-box !important",
    flexWrap: "wrap",
  },
  ticket: (src, isArabic) => ({
    width: "100%",
    backgroundImage: `url(${src})`,
    backgroundSize: "100% 100%",
    display: "flex",
    flexDirection: isArabic ? "row-reverse" : "row",
    justifyContent: "space-between",
    textAlign: "start",
    boxSizing: "border-box !important",
    padding: "25px 20px",
    margin: "3px 0",
    color: "#000",
  }),
  ticketInfoBox: (isArabic) => ({
    display: "flex",
    flexDirection: isArabic ? "row-reverse" : "row",
    alignItems: "center",
    gap: "20px !important",
  }),
  ticketTypeBox: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
  },
  ticketType: (isArabic) => ({
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "800",
    lineHeight: "normal",
  }),
  info: {
    color: "rgb(80, 80, 80)",
  },
  avail: {
    color: `var(--Dark, #060B0C)`,
    fontFamily: `Inter`,
    fontSize: `10px`,
    fontStyle: `normal`,
    fontWeight: `600`,
    lineHeight: `normal`,
    padding: "3px 0",
  },
  priceBox: (isArabic) => ({
    display: "flex",
    alignItems: "end",
    fontSize: "13px",
    // fontSize: "10px",
    color: `var(--Dark, #060B0C)`,
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    gap: "5px",
  }),
  price: (isArabic) => ({
    color: `var(--Dark, #060B0C)`,
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  }),
  numBox: (isArabic) => ({
    display: "flex",
    flexDirection: isArabic ? "row-reverse" : "row",
    alignItems: "center",
    gap: "8px",
  }),
  remove: {
    background: "rgba(50, 50, 50, 0.3)",
    borderRadius: "100%",
    fontSize: "19px",
    padding: "2px",
    cursor: "pointer",
  },
  add: {
    cursor: "pointer",
  },
  button: (isArabic) => ({
    background: "#FFBA83",
    color: "black",
    position: { xs: "absolute", sm: "static" },
    width: { xs: "calc(100% - 40px)", sm: "100%" },
    bottom: "20px",
    left: "20px",
    display: "flex",
    flexDirection: isArabic ? "row-reverse" : "row",
    justifyContent: "space-between",
    maxWidth: { xs: "600px", sm: "335px" },
    height: "60px",
    flexShrink: "0",
    borderRadius: "16px",
    boxshadow: `0px 4px 4px 0px rgba(0, 0, 0, 0.25)`,
    boxSizing: "border-box",
  }),
  buttonHead: {
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  buttonPrice: (isArabic) => ({
    textAlign: "right",
    fontFamily: isArabic ? "Cairo, sans-serif" : "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  }),
};
